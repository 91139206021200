// @flow
import type { CountryType } from 'types/resources/country';
import { useEffect, useRef } from 'react';
import { API } from 'api';
import { isAuthenticated } from 'utils/user';
// $FlowFixMe
import { globalHistory } from '@reach/router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { clearTokenCache } from 'api/api';
import { sentryCapture } from 'services/sentry';
import Analytics from 'services/analytics';

const excludedRoutes = ['/password/forgot', /^\/auth(?:\/.*)?$/];

const isExcludedRoute = pathname =>
  excludedRoutes.some(route => {
    if (route instanceof RegExp) return route.test(pathname);
    return route === pathname || pathname.startsWith(route);
  });

const useGoogleOneTap = () => {
  const onAuthorize = useStoreActions(a => a.user.onAuthorize);
  const { id: currentCountryId }: CountryType = useStoreState(s => s.country.current);

  const timeoutIdRef = useRef(null);

  const handleCredentialResponse = response => {
    clearTokenCache(); // clear throttleAdapterEnhancer cache manually
    return API.post('/auth/google-login', {
      token: response.credential,
      country_id: currentCountryId,
    })
      .then(res =>
        onAuthorize(res).then(user => {
          if (res.signup) Analytics.signup(user, 'google', { Method2: 'google-one-tap' });
          return user;
        })
      )
      .catch(err => {
        console.log('ERROR', err);
        sentryCapture(err.message);
      });
  };

  const initializeGoogleOneTap = () => {
    const { google } = window;
    if (
      !google ||
      isAuthenticated() ||
      isExcludedRoute(window.location.pathname) ||
      !currentCountryId
    )
      return;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE,
      callback: handleCredentialResponse,
      use_fedcm_for_prompt: false, // FedCM for One Tap will become mandatory starting Oct 2024. https://developers.google.com/identity/gsi/web/guides/fedcm-migration
      // cancel_on_tap_outside: false,
    });

    google.accounts.id.prompt();
  };

  const clearGoogleOneTapTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const scheduleGoogleOneTap = () => {
    clearGoogleOneTapTimeout();

    // Safari doesn't support requestIdleCallback
    if ('requestIdleCallback' in window) requestIdleCallback(initializeGoogleOneTap);
    else timeoutIdRef.current = setTimeout(initializeGoogleOneTap, 10000); // 10 seconds delay in safari
  };

  globalHistory.listen(({ action }) => action === 'PUSH' && scheduleGoogleOneTap());

  useEffect(() => {
    if (!isAuthenticated()) window.onload = scheduleGoogleOneTap();
    return () => clearGoogleOneTapTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryId]);
};

export default useGoogleOneTap;
